var JSCODE = JSCODE || {};

$(document).ready(function () {
    JSCODE.init();
});

$(window).resize(function () {
});

JSCODE.init = function () {
    this.slide_language();
    this.slide_testimonials();
    this.slide_consultas();
    this.scroll_fixed();
};

JSCODE.slide_language = function () {
    var $scope = $('*[data-scope="slider"]:first');
    var $controls = $('*[data-scope="controls"]:first');
    $scope.slick({
        autoplay: true,
        autoplaySpeed: 5000,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: false,
        asNavFor: $controls,
        speed: 200,
        dots: false,
        cssEase: 'linear',
        initialSlide: 0,
        adaptiveHeight: true
    });

    $controls.slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        asNavFor: $scope,
        dots: false,
        focusOnSelect: true
    });
}

JSCODE.slide_consultas = function () {
    var $consultas = $('*[data-scope="consultas"]:first');

    $consultas.slick({
        centerMode: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        variableWidth: true,
        arrows: true,
        adaptiveHeight: true,
        focusOnSelect: true,
        prevArrow: '<i class="icon-arrow-left-2"></i>',
        nextArrow: '<i class="icon-arrow-right-2"></i>',
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1
                }
            }]
    });
}

JSCODE.slide_consultas = function () {
    var $consultas = $('*[data-scope="consultas"]');

    $consultas.slick({
        centerMode: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        variableWidth: true,
        arrows: true,
        adaptiveHeight: true,
        focusOnSelect: true,
        prevArrow: '<i class="icon-arrow-left-2"></i>',
        nextArrow: '<i class="icon-arrow-right-2"></i>',
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1
                }
            }]
    });
}

JSCODE.scroll_fixed = function () {
    var $header = $('*[data-scope="header"]'),
        $groupHeader = $('*[data-scope="group-header"]', $header),
        $distance = $('*[data-scope="content"]').offset().top,
        $header_fixed = false;

    $(window).scroll(function () {

        if ($(document).scrollTop() > $distance) {
            if ($header_fixed == false) {
                $groupHeader.hide().addClass('fixed').fadeIn(200);
            }
            $header_fixed = true;
        } else {
            if ($header_fixed == true) {
                $groupHeader.fadeOut(200, function () {
                    $groupHeader.removeClass('fixed').show();
                });
            }
            $header_fixed = false;
        }

    });
}

JSCODE.slide_testimonials = function () {
    $('.box-row-testimonials').slick({
        dots: false,
        infinite: true,
        speed: 300,
        slidesToShow: 2,
        adaptiveHeight: true,
        prevArrow: '<div class="arrow left"><svg style="width:24px;height:24px" viewBox="0 0 24 24"><path d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z" /></svg></div>',
        nextArrow: '<div class="arrow rigth"><svg style="width:24px;height:24px" viewBox="0 0 24 24"><path d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" /></svg></div>',
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1
                }
            }]
    });
}

jQuery.validator.addMethod("celular", function (value, element) {
    value = value.replace("(", "");
    value = value.replace(")", "");
    value = value.replace("-", "");
    value = value.replace("_", "");
    value = value.replace(" ", "");
    return this.optional(element) || /[0-9]{10}/.test(value) || /[0-9]{11}/.test(value);
}, "Informe um celular válido");

// JSCODE.form_validate = function () {
//     $('*[data-validate-form]:first').validate();
// }

$(document).on('click', '.btClose', function (e) {
    e.preventDefault();
    $('.header .menu').removeClass('on');
    $('body').css('overflow', 'auto');
    return;
});

$(document).on('click', '.btMenu', function (e) {
    e.preventDefault();
    $('.header .menu').addClass('on');
    $('body').css('overflow', 'hidden');
    return;
});

$(document).on('click', '.menu', function (e) {
    if (e.offsetX < 0) {
        $('.header .menu').removeClass('on');
        $('body').css('overflow', 'auto');
    }
});

$(document).on('click', '.menu a', function (e) {
    var id = $(this).attr('id');
    if (id != "dev") {
        $('.header .menu').removeClass('on');
        $('body').css('overflow', 'auto');
    }
});


$(document).on('click', 'footer .li-header', function (e) {
    e.preventDefault();
    var id = $(this).attr('id');
    $("." + id).toggleClass('expand');
    return;
});

// SCROL DOWN ARROW CODE

$(window).scroll(function () {
    if ($(window).scrollTop() > 20) {
        $(".bounce").css("display", "none");
    } else {
        $(".bounce").css("display", "block");
    }
});

// FINISH SCROLL DOWN ARROW CODE




